<template>
  <header
    class="
      flex-space-between-center
      h-80
      p-l-50
      p-r-78
      position-fixed
      w-1280
      bg-#f1f3f9
      z-index-999
    "
  >
    <img alt="Vue logo" src="./assets/1.png" class="w-202" />
    <div class="fs-20">
      <span
        class="p-r-62 cursor-pointer"
        :class="tab === 0 ? 'c-#038EF9FF' : 'c-#50565DFF'"
        @click="changeTab(0)"
      >
        首页
      </span>
      <span
        class="p-r-41 cursor-pointer"
        :class="tab === 1 ? 'c-#038EF9FF' : 'c-#50565DFF'"
        @click="changeTab(1)"
      >
        产品介绍
      </span>
      <span
        class="p-r-41 cursor-pointer"
        :class="tab === 2 ? 'c-#038EF9FF' : 'c-#50565DFF'"
        @click="changeTab(2)"
      >
        行业优势
      </span>
      <span
        class="cursor-pointer"
        :class="tab === 3 ? 'c-#038EF9FF' : 'c-#50565DFF'"
        @click="changeTab(3)"
      >
        联系我们
      </span>
    </div>
  </header>
  <section class="d-flex flex-direction-column p-t-80">
    <img id="section0" src="./assets/2.png" alt="" class="w-1280" />
    <div id="section1" class="position-relative">
      <img  src="./assets/3.png" alt="" class="w-1280" />
      <a :href="downloadConfig.WIN_X32" download class="download-btn position-absolute left-140">
      </a>
      <a :href="downloadConfig.WIN_X64" download class="download-btn position-absolute left-426">
      </a>
      <a :href="downloadConfig.MAC_INTEL" download class="download-btn position-absolute left-710">
      </a>
      <a :href="downloadConfig.MAC_APPLE" download class="download-btn position-absolute left-996">
      </a>
    </div>
    <img id="section2" src="./assets/4.png" alt="" class="w-1280" />
    <img id="section3" src="./assets/5.png" alt="" class="w-1280" />
  </section>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios';


export default {
  setup () {
    const tab = ref(0)
    const clickTab = ref(-1)
    const heightList = []
    const downloadConfig = ref({})
    axios.get('https://static.haishixuetang.com/package/downloadConfig.json', {
      params: {
          t: Date.now()
        }
    }).then(res => {
      downloadConfig.value = res.data.data
    })

    onMounted(() => {
      const ls = [1, 2, 3, 4]
      setTimeout(() => {
        ls.forEach((i, idx) => {
          const div = document.getElementById('section' + idx);
          heightList.push(((div && div.offsetTop) || 0) - 80);
        });
      }, 500)

      window.addEventListener('scroll', () => {
        const height = document.documentElement.scrollTop
        heightList.forEach((i, idx) => {
          if (
            (height <= heightList[idx + 1] && height >= heightList[idx]) ||
            (idx === 0 && height < heightList[idx]) ||
            (idx === heightList.length - 1 && height >= heightList[idx])
          ) {
            if (tab.value !== clickTab.value) {
              tab.value = idx
            }
            if (tab.value === idx) {
              clickTab.value = -1
            }
          }
        });
      });
    })

    function changeTab (id) {
      tab.value = id
      clickTab.value = id
      window.scrollTo({
        top: document.getElementById('section' + id).offsetTop - 80,
        behavior: "smooth"
      });
    }

    return {
      tab,
      changeTab,
      downloadConfig
    }
  }
}

// 140 426 710 996
</script>

<style scoped>
a {
  text-decoration: none;
  color: #fff;
}
.download-btn {
  width: 146px;
  height: 46px;
  position: absolute;
  top: 1480px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("./assets/download-btn.png");
}
.download-btn:hover {
  background-image: url("./assets/download-btn-hover.png");
}
</style>
